<template>
	<div>
		<NavBar :navSelIndex="5"></NavBar>

		<b-container fluid class="tool-bg">
			<b-row class="tool-row">
				<b-col class="tool-col" cols="12">
					<img class="tool-bg-img" src="../../../public/icon/tool/ekd.jpg" />
					<div class="tool-img-box">
						<!-- <img class="tool-icon-img" src="../../../public/icon/tool/e1.png" /> -->
						<div class="tool-title">{{$t('portalEpeidaTitle')}}</div>
						<div class="tool-subtitle">{{$t('portalEpeidaSubtitle')}}</div>
						<div class="tool-button-box">
							<div class="tool-button" @click="toEPeida">{{$t('toolButtonTitle')}}</div>
						</div>
					</div>

				</b-col>
			</b-row>
		</b-container>

		<Right></Right>
		<Footer></Footer>
	</div>
</template>

<script>
	import NavBar from "@/components/Navbar.vue";
	import Footer from "@/components/Footer.vue"
	import Right from "@/components/Right.vue"
	import dump from "../../util/dump.js"
	export default {
		components: {
			NavBar,
			Footer,
			Right
		},
		data() {
			return {

			};

		},
		methods: {
			toEPeida() {
				if (!dump.checkLogin()) {
					return false;
				}
				this.$api.epeida({}).then(res => {
					dump.toEPeida(res);
				})
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
	@import "../../assets/scss/style.scss";
	body{
		background: #1e1e1e;
	}
	.row {

		margin-right: 0;
		margin-left: 0;
	}

	.tool-bg {
		padding-right: 0;
		padding-left: 0;

		.tool-row {
			.tool-col {
				width: 100%;
				margin-right: 0;
				margin-left: 0;
				padding-left: 0;
				padding-right: 0;
				.tool-bg-img {
					width: 100%;
					height: auto;
				}

				.tool-img-box {
					position: absolute;
					z-index: 99;
					top: 20%;
					width: 100%;

					.tool-icon-img {
						width: 13.75rem;
						height: auto;
					}

					.tool-title {
						font-size: 2.1875rem;
						letter-spacing: 0.375rem;
						text-align: center;
						color: #FFFFFF;
					}

					.tool-subtitle {
						font-size: 0.8125rem;
						word-spacing: 0.375rem;
						text-align: center;
						color: #FFFFFF;
					}

					.tool-button-box {
						padding-top: 3.75rem;

						.tool-button {
							padding: 0.75rem 0;
							color: #fff;
							font-size: 1.25rem;
							font-weight: bold;
							width: 10.8125rem;
							margin: auto;
							background: $bg-default-s-color;
							cursor: pointer;
						}
					}


				}
			}

		}
	}
</style>
